//
// Indent Utilities
// --------------------------------------------------

@mixin indent-responsive($preffix, $rules, $medias, $offsets) {

	@if ($preffix != '' and $preffix != null) {
		$preffix: #{$preffix}-;
	}

	@each $resolution, $alias in $medias {
		@if ($resolution == 0) {
			@each $offset in $offsets {
				.#{$preffix}#{strip-unit($offset)} {
					@each $rule in $rules {
						#{$rule}: $offset !important;
					}
				}
			}
		} @else {
			@media (min-width: $resolution) {
				@each $offset in $offsets {
					.#{$preffix}#{$alias}-#{strip-unit($offset)} {
						@each $rule in $rules {
							#{$rule}: $offset !important;
						}
					}
				}
			}
		}
	}
}

